import React from "react"
import desarrolloCss from "../../css/desarrollo.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ChatIMG from "../../components/images/chatbot/chatbot"
import MenuDesarrollo from "../../components/menuDesarrollo/menuDesarrollo"
import LogoBlanco from "../../images/logo-blanco.svg"
import { Link } from "gatsby"

const Chat = () => (
  <>
    <div className={desarrolloCss.background}>
      <div className={desarrolloCss.gradient}></div>
    </div>
    <ChatIMG></ChatIMG>
    <Link to="/" className={desarrolloCss.logoBlanco}>
    <img src={LogoBlanco} alt="Logotipo de engrane version blanca"/>
    </Link>
    <MenuDesarrollo></MenuDesarrollo>
    <div className={desarrolloCss.container}>
      <Layout siteTitle="Desarrollo">
        <SEO title="Chat Bot" />
        <div className={desarrolloCss.contenido}>
          <h1 className={desarrolloCss.titulo}>
            chat <br />
            bot
          </h1>
          <p className={desarrolloCss.text}>
            Si buscas interactuar con tus clientes y/o empleados, pero no sabes
            cual es la mejor forma de hacerlo, nosotros podemos ayudarte ya que
            existe ChatBot que responde de manera automática a los clientes o
            empleados . ¿Cómo funciona? Por medio de Messenger, WhatsApp,
            Telegram o chat masivos como los de Twitch. ¡Además! tener un
            chatbot es más rentable que capacitar y responder las mismas
            preguntas todos los días.
          </p>
        </div>
      </Layout>
    </div>
    <Link className={desarrolloCss.productos}>
      PRODUCTOS <span>&nbsp;&nbsp;&nbsp;→</span>
    </Link>
  </>
)

export default Chat
